<template>
  <span v-if="streams">
    {{ streams }}
  </span>
  <span v-else>
    <span v-for="s,n of streams" :key="n">
      <!-- build player table.. -->
      {{ s.gameMode }} - {{ s.playerName }} - {{ s.region }}<br>
      <span v-for="(p, i) of s.players" :key="i">
        [{{ p.level }}] {{ p.name }} - {{ p.scores.creepScore }} - {{ p.scores.kills }} / {{ p.scores.deaths }} / {{ p.scores.assists }} <br>
      </span><br>
      Test {{ s }}
    </span>
  </span>
</template>

<script>
  import {
    mapGetters,
    mapActions,
    mapState,
  } from 'vuex'
  export default {
    name: 'Streams',
    data () {
      return {
        x: {},
      }
    },
    computed: {
      ...mapState('node', ['nodes', 'invites', 'curRegion', 'featured', 'streams']),
      ...mapGetters('node', ['node']),
    },
    watch: {
      node () {
        this.getGames()
        console.log('nchange')
      },
    },
    async mounted () {
      this.getGames()
    },
    methods: {
      ...mapActions('node', ['setNode', 'grabStreams']),
      async getGames () {
        this.grabStreams()
      },
    },
  }
</script>
